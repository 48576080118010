.fullWindowed {
  @mixin absolutePosition fixed;
  @mixin size 100vw, 100vh;
  z-index: 9999;
  overflow-x: hidden;
  overflow-y: scroll;
}

.windowed {
  @mixin size 100%;
  @mixin zIndexPosition mediaCard, contentWindowed;
  position: relative;

  @media screen and (orientation: portrait) {
    &--padded {
      padding: 0 0 3.125rem;
      background-color: var(--color_black);

      @media (--mq-from-laptop) {
        padding: 0;
        background: none;
      }
    }
  }
}

.fullscreen {
  @mixin size 100%;
  position: relative;

  @media screen and (orientation: portrait) {
    &--noPaddings {
      &:-webkit-full-screen {
        padding: 0;
      }

      &:-moz-full-screen {
        padding: 0;
      }

      &:-ms-fullscreen {
        padding: 0;
      }

      &:fullscreen {
        padding: 0;
      }
    }
  }
}
