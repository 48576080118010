.button {
  display: inline-block;
  padding: 0.625rem 1.25rem;
  transition:
    color 300ms ease-in-out,
    background 300ms ease-in-out;

  --color_button-icon: var(--color_white);

  --color_button-primary-bg: var(--color_gallery);
  --color_button-primary-fg: var(--color_black);
  --color_button-primary-fg-hover: var(--color_black);
  --color_button-primary-hover-bg: var(--color_grey-100);

  --color_button-secondary-tv-bg: var(--color_darkmode-secondary);
  --color_button-secondary-tv-fg: var(--color_white-70);
  --color_button-disabled-tv-bg: var(--color_button-tv);
  --color_button-disabled-tv-fg: var(--color_white-70);
  --color_button-subtext-fg: var(--color_white-70);
  --color_button-tvod-primary-bg: var(--color_tvod-primary);

  [data-theme~='dark'] & {
    --color_button-primary-bg: var(--color_mine-shaft);
    --color_button-primary-fg: var(--color_white);
    --color_button-primary-fg-hover: var(--color_white);
    --color_button-primary-hover-bg: var(--color_mine-shaft-light);
  }

  [data-theme~='telecomitalia'] & {
    --color_button-icon: var(--color_black);

    --color_button-primary-bg: var(--color_gold-dark-secondary);
    --color_button-primary-fg: var(--color_white);
    --color_button-primary-fg-hover: var(--color_white);
    --color_button-primary-hover-bg: var(--color_gold-dark-tertiary);

    --color_button-secondary-tv-bg: var(--color_gold-dark-secondary);
    --color_button-secondary-tv-fg: var(--color_white);
    --color_button-disabled-tv-bg: var(--color_gold-dark-secondary);
    --color_button-disabled-tv-fg: var(--color_white);
    --color_button-subtext-fg: var(--color_white);
    --color_button-tvod-primary-bg: var(--color_white);

    transition: background 300ms ease-in-out;

    @mixin tvDevice {
      transition: none;
    }

    &__dark {
      color: var(--color_black);
      background-color: var(--color_white);

      &:hover {
        background-color: var(--color_white-70);
      }
    }
  }

  @mixin tvDevice {
    /* for tv device, the primary button hover color don't change we need it for LG Pointer*/
    --color_button-primary-fg-hover: var(--color_button-primary-fg);
    --color_button-primary-hover-bg: var(--color_button-primary-bg);
  }

  &--mobile {
    padding: 0.9375rem 0;
    font-size: 1.125rem;

    @media (--mq-from-tablet) {
      padding: 0.625rem 2.5rem;
      font-size: inherit;
    }
  }

  &--fullWidth {
    display: block;
    width: 100%;
  }

  &--isV5Style {
    font-family: var(--font_canal);
    font-size: 1.125rem;
    line-height: 1.375rem;
    color: var(--color_white-80);
    text-transform: uppercase;
    border-radius: 0.25rem;

    @mixin tvDevice {
      height: 5.625rem;
      padding: 1.25rem 1.875rem;
      font-size: 2rem;
      line-height: 2.25rem;
      border-radius: 0.5rem;
    }

    &--big {
      padding: 0.875rem 0.75rem;
      font-size: 1rem;
      line-height: 1.25rem;

      @mixin tvDevice {
        padding: 1.25rem 1.5rem;
      }
    }

    .button {
      &__label {
        &--big {
          @mixin lineClamp 1;
          word-break: break-all;
          white-space: nowrap;

          @mixin tvDevice {
            font-size: 1.75rem;
            text-align: initial;
            white-space: normal;
          }
        }
      }
    }
  }

  &__oval {
    padding: 0.625rem;
    font-size: 0.875rem;
    color: var(--color_white);
    text-align: center;
    white-space: nowrap;
    text-decoration: none;
    cursor: pointer;
    background: transparent;
    border-radius: var(--border-radius-small);
    transition:
      border 200ms ease-out,
      background 200ms ease-out;

    &:hover {
      cursor: pointer;
    }
  }

  &__round {
    border-radius: var(--border-radius-big);
  }

  &__icon {
    color: var(--color_button-icon);

    + .button__label {
      display: inline-block;
      margin-left: 0.625rem;

      &--big {
        margin-left: 0.375rem;
      }
    }
  }

  &__subtext {
    color: var(--color_button-subtext-fg);
  }

  &.button--active {
    color: var(--color_white);
    background: var(--color_secondary);
  }

  &__disabled {
    color: var(--color_button-disabled-fg);
    cursor: default;
    background-color: var(--color_button-disabled-bg);

    @mixin tvDevice {
      color: var(--color_button-disabled-tv-fg);
      background-color: var(--color_button-disabled-tv-bg);
      opacity: 0.5;
    }
  }

  &__primary,
  &__secondary,
  &__secondaryDisabled,
  &__tvodPrimary {
    color: var(--color_button-fg);
  }

  &__primary {
    color: var(--color_button-primary-fg);
    background-color: var(--color_button-primary-bg);

    &:hover {
      color: var(--color_button-primary-fg-hover);
      background-color: var(--color_button-primary-hover-bg);
    }
  }

  &__primaryInverted {
    color: var(--color_grey-800);
    border: 1px solid var(--color_grey-350);

    &:hover {
      color: hsl(345, 20%, 12%); /* #24181b */
    }
  }

  &__secondary {
    background-color: var(--color_secondary);
    border-radius: var(--border-radius-big);

    &:hover {
      background-color: var(--color_link-hover);
    }

    @mixin tvDevice {
      color: var(--color_button-secondary-tv-fg);
      background-color: var(--color_button-secondary-tv-bg);
    }
  }

  &__secondaryDisabled {
    cursor: default;
    background-color: var(--color_secondary);
    opacity: 0.5;
  }

  &__secondaryInverted {
    color: var(--color_secondary);
    border: 1px solid var(--color_grey-200);
  }

  &__tv {
    color: var(--color_button-secondary-tv-fg);
    background-color: var(--color_button-secondary-tv-bg);
  }

  &__tvodPrimary {
    background-color: var(--color_button-tvod-primary-bg);

    &:disabled {
      background-color: var(--color_grey-200);
    }
  }

  &__dark {
    color: var(--color_darkmode-textcolor-primary);
    background-color: var(--color_darkmode-button);

    &:hover {
      background-color: var(--color_darkmode-button-hover);
    }
  }

  &__default {
    color: currentColor;
    background: transparent;
  }

  &__actionGroup {
    display: flex;
    align-items: center;
  }

  &__label {
    @mixin tvDevice {
      font-size: 2rem;

      &--big {
        font-size: 1.75rem;
        @mixin lineClamp 1;
      }
    }
  }

  &:global(.tv__focus),
  &[data-tv-focus='true'] {
    @mixin tv-focus-button-base;
  }
}

:global {
  @mixin tvDevice {
    .tv__focus {
      .globalButton {
        @mixin tv-focus-button-base;
      }
    }
  }
}
