.HeaderOneDiscovery--searchOpen {
  height: var(
    --size_mobileHeader_height-searchOpen
  ); /* Hide the navigation only for mobile when search is open */
  overflow: hidden;

  @media (--mq-from-tablet) {
    height: auto;
    overflow: visible;
  }
}
