.whoIsWatching {
  z-index: 53;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100vw;
  min-height: 100vh;
  background-color: var(--color_background-v5);

  &--hidden {
    position: absolute;
    opacity: 0;
  }

  &__logo {
    padding: 0.625rem 0 0;

    @mixin tvDevice {
      display: flex;
      width: 100%;
      padding: 3.5rem;
    }
  }

  &__title {
    margin: 0 0 1.5rem;
    font-family: var(--font_hind);
    font-size: 1.625rem;
    text-align: center;

    &--fadeIn {
      animation: 0.3s ease-in both fadein;
    }

    @mixin tvDevice {
      margin: 0 0 3rem 0;
      font-size: 2.625rem;
    }
  }

  &__toggle {
    width: 100%;
    max-width: 30.5rem;
    padding: 0 1rem 2rem 1rem;
    margin: 1.5rem 0 0;

    &--fadeIn {
      animation: 0.3s ease-in 0.4s both fadein;
    }

    @media (--mq-from-tablet) {
      padding: 0 0 3.75rem 0;
    }

    @mixin tvDevice {
      max-width: 61.75rem;
      padding: 0 0 7.5rem 0;
    }
  }

  &__toggleSubtitle {
    padding: 0 0 0 0.5rem;
    margin: 0.5rem 0 0;
    font-family: var(--font_hind);
    font-size: 0.75rem;
    color: var(--color_white-50);

    @mixin tvDevice {
      margin: 1rem 0 0;
      font-size: 1.5rem;
    }
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
