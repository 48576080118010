.ProfileModalSelectorMyCanal {
  &__profilesModal {
    --color_modal-profile-tv-bg: var(--color_profile-background-tv, #16191c);
    [data-theme~='telecomitalia'] & {
      --color_modal-profile-tv-bg: var(--color_gold-kumera, #986d23);
    }
    &--no-scrollBar {
      @mixin hideScrollbar;
    }

    @mixin tvDevice {
      background-color: var(--color_modal-profile-tv-bg);
    }
  }

  &__modalMaxReached {
    .ProfileModalSelectorMyCanal__modalMaxReached__body {
      @media (--mq-from-tablet) {
        padding: 2.5rem;
      }

      @mixin tvDevice {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 3rem;
      }
    }
  }
}
