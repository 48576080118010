.ChoiceButton {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 1rem 1.25rem 0.5625rem;
  margin-top: 1rem;
  font-size: 2.5rem;
  line-height: 2.625rem;
  color: var(--color_GDPR-button-fg);
  background-color: var(--color_GDPR-button-bg);
  border-radius: var(--border-radius-extraLarge);

  &__icon {
    width: 3.125rem;
    fill: var(--color_GDPR-icon-fg);
  }

  &__label {
    color: var(--color_GDPR-choice-fg);
  }
}
