@import '../../assets/base_css/_animations.css';

.spinner {
  position: absolute;
  z-index: 10;
  border-color: var(--color_secondary) transparent var(--color_secondary)
    var(--color_secondary);
  border-style: solid;
  border-width: 0.3125rem;
  border-radius: 50%;
  opacity: 0;
  transform: translate3d(-50%, -50%, 0);
  animation:
    rotate 1s linear infinite,
    opacity 0.3s linear 1s 1 normal forwards;

  &__withKeyboard {
    margin-top: 1.25rem;

    @media (--mq-from-tablet) {
      margin-top: 0;
    }
  }

  &__unsetPosition {
    position: unset;
    margin: auto;
  }

  &--hidden {
    display: none;
  }
}
