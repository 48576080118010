.avatarSelection {
  @mixin size 100%;

  @mixin tvDevice {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__title {
    font-family: var(--font_hind);
    font-size: 1.875rem;
    text-align: center;

    @mixin tvDevice {
      margin: 4.6875rem auto 8.375rem;
      font-family: var(--font_hind);
      font-size: 2.9375rem;
      font-weight: 600;
    }

    @mixin isDark {
      color: var(--color_darkmode-textcolor-primary);
    }
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    max-width: 37.5rem;
    margin: auto;

    @media (--mq-from-tablet) {
      grid-template-columns: repeat(5, 1fr);
      grid-gap: 1.25rem;
    }

    @mixin tvDevice {
      @mixin noScrollBar;
      display: flex;
      flex-flow: row wrap;

      max-width: 78.125rem;
      padding: 0.875rem 0.625rem 9.8125rem;
    }
  }

  &__item {
    @mixin size 5.3125rem;
    place-self: center;
    margin: 0.625rem 0;

    @media (--mq-from-tablet) {
      width: 6.25rem;
    }

    @mixin tvDevice {
      @mixin size 11.625rem;
      padding: 0.25rem;
      margin: 0.9375rem;

      &__avatar {
        @mixin size 100%;
      }
    }

    &__avatarImg {
      width: 100%;
    }
  }
}

:global {
  @mixin tvDevice {
    .globalAvatarSelection.tv__focus img {
      @mixin tv-focus-shadow-rounded;
    }
  }
}
