.profilesListTV {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  margin: auto;

  &__title {
    font-family: var(--font_hind);
    font-size: 2.9375rem;
    font-weight: 600;
    color: var(--color_white);
    text-align: center;
  }

  &__list {
    @mixin tvDevice {
      @mixin noScrollBar;
    }

    display: flex;
    width: 100%;
    max-width: 100vw;
    padding: 0.25rem 6.25rem 0;
    margin: 3rem 0;
    overflow: auto;
  }

  &__item {
    text-align: center;

    &:not(:first-child) {
      margin-left: 3rem;
    }
  }

  &__avatar {
    @mixin size 13rem;
  }

  &__profileAvatar {
    @mixin size 3.4375rem;
  }

  &__nameWrapper {
    width: 12rem;
    margin-top: 0.75rem;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__name {
    font-size: 2.0625rem;
    color: var(--color_white-70);
  }
}

:global {
  @mixin tvDevice {
    .globalProfilesListTV.tv__focus img {
      @mixin tv-focus-shadow-rounded;
    }
  }
}
