.ProfileDeleteConfirmation {
  --color_bg: var(--od-color_darkmode-primary);
  --color_text-fg: var(--color_white-50);

  [data-theme~='telecomitalia'] & {
    --color_bg: var(--color_gold-kumera);
    --color_text-fg: var(--color_white-70);
  }
  height: 100vh;

  background-color: var(--color_bg);
  @mixin centered;

  @mixin tvDevice {
    width: 100%;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  &__title {
    font-family: var(--font_hind);
    font-size: 2.9375rem;
    font-weight: 600;
  }

  &__text {
    margin-top: 1.875rem;
    font-size: 1.75rem;
    color: var(--color_text-fg);
  }

  &__buttonWrapper {
    display: flex;
    flex-direction: column;
    margin-top: 1.875rem;
  }

  &__button {
    @mixin size 30rem, 4.6875rem;
    padding: 0.125rem 1.125rem 0.125rem 1.125rem;
    margin: auto;
    font-size: 2.25rem;
    border-radius: var(--border-radius-extraLarge);

    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }
}

:global {
  @mixin tvDevice {
    .ProfileDeleteConfirmation .tv__focus {
      @mixin tv-focus-button;

      border-radius: 8.3px;
    }
  }
}
