.profileForm {
  --color_kidsSettings-description-fg: var(--color_cod-grey-50);

  [data-theme~='dark'] & {
    --color_kidsSettings-description-fg: var(--color_white-70);
  }

  [data-theme~='telecomitalia'] & {
    --color_kidsSettings-description-fg: var(--color_white);
  }
  max-width: 40.625rem;

  margin: auto;

  @mixin tvDevice {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: none;
  }

  &__title {
    margin: 0 0 2.8125rem;
    font-family: var(--font_hind);
    font-size: 1.875rem;
    text-align: center;

    @mixin tvDevice {
      margin-top: 4.6875rem;
      font-family: var(--font_hind);
      font-size: 2.9375rem;
      font-weight: 600;
    }
  }

  &__profileAvatarWrapper {
    display: flex;
    justify-content: center;
    width: 6.25rem;
    margin: 1.25rem auto;

    @mixin tvDevice {
      @mixin size 13rem;
    }
  }

  &__profileAvatarEdit {
    width: 100%;
  }

  &__profileAvatar {
    @mixin size 1.5625rem;

    @mixin tvDevice {
      @mixin size 3.4375rem;
    }
  }

  &__kidsSettings {
    @mixin tvDevice {
      width: 47.5rem;
      margin: 0 auto 4rem;
    }

    &__title {
      font-family: var(--font_hind);
      font-size: 1.25rem;
      font-weight: 600;
    }

    &__description {
      max-width: 31.25rem;
      margin: 0;
      color: var(--color_profileForm-kidsSettings-description-fg);

      @media (--mq-from-tablet) {
        margin-top: 0.625rem;
      }

      @mixin tvDevice {
        max-width: none;
        font-size: 1.75rem;
      }
    }
  }

  &__name {
    @mixin size 100%, 3.125rem;
    padding: 0.9375rem;
    margin-bottom: 1.25rem;
    font-size: 1.125rem;
    color: var(--color_profileForm-name-light-fg);
    background: var(--color_profileForm-name-light-bg);
    border: 0.0625rem solid var(--color_profileForm-name-border);
    border-radius: var(--border-radius-medium);

    &::placeholder {
      color: var(--color_standard_placeholder);
    }

    @mixin isDark {
      color: var(--color_profileForm-name-dark-fg);
      background: var(--color_profileForm-name-dark-bg);

      &::placeholder {
        color: var(--color_standard_placeholder);
      }
    }

    @mixin tvDevice {
      display: flex;
      width: 47.5rem;
      height: 4.125rem;
      margin: 0 auto 0.625rem;
      font-size: 2.625rem;
      color: var(--color_black);
      pointer-events: none;
      background-color: var(--color_white);
      border: 0;
      border-radius: var(--border-radius-extraLarge);

      &::placeholder {
        color: var(--color_standard_placeholder);
      }
    }
  }

  &__keyboard {
    display: flex;
    justify-content: center;
    margin-bottom: 1.875rem;
  }

  &__buttonWrapper {
    @mixin tvDevice {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-bottom: 9.8125rem;
    }
  }

  &__button {
    @mixin size 100%, 3.125rem;
    margin-bottom: 1.25rem;
    font-size: 1.125rem;

    @mixin tvDevice {
      @mixin size 30rem, 4.6875rem;
      padding: 0.125rem 1.125rem 0.125rem 1.125rem;
      margin-bottom: 0.875rem;
      font-size: 2.25rem;
      border-radius: var(--border-radius-extraLarge);
    }
  }
}

:global {
  @mixin tvDevice {
    .profileFormInputFocus.tv__focus {
      @mixin tv-focus-shadow;
    }

    .profileFormAvatar.tv__focus img {
      @mixin tv-focus-shadow-rounded;
    }

    .profileFormKidsSetting.tv__focus,
    .profileFormSubmitButtonFocus.tv__focus,
    .profileFormDeleteButtonFocus.tv__focus {
      @mixin tv-focus-button-with-shadow;

      font-size: 2.3125rem;
    }
  }
}
