.profilesList {
  --color_title-fg: initial;
  --color_profile-border: var(--color_profilesList-profile-border-light);
  --color_label-fg: initial;

  [data-theme~='dark'] & {
    --color_profile-border: var(--color_profilesList-profile-border-dark);
    --color_label-fg: var(--color_darkmode-textcolor-primary);
  }

  [data-theme='tvod light'] & {
    --color_title-fg: var(--color_profilesList-light-fg);
    --color_label-fg: var(--color_profilesList-light-fg);
  }

  [data-theme='tvod dark'] & {
    --color_title-fg: var(--color_profilesList-dark-fg);
    --color_label-fg: var(--color_profilesList-dark-fg);
  }
  width: 100%;
  max-width: 40.625rem;

  margin: 0 auto 8.75rem;

  @media (--mq-from-tablet) {
    margin-bottom: 5.625rem;
  }

  &__title {
    margin: 0;
    font-family: var(--font_hind);
    font-size: 1.875rem;
    font-weight: 400;
    color: var(--color_title-fg);
    text-align: center;
  }

  &__list {
    margin: 1.875rem 0;
  }

  &__profile {
    width: 100%;
    height: 5rem;
    margin: 0.625rem auto;
    cursor: pointer;
    border: 1px solid var(--color_profile-border);
    border-radius: 3px;
    transition: box-shadow 0.1s linear;

    @media (--mq-from-tablet) {
      width: 70%;
    }

    &:first-child {
      height: 6.25rem;
      margin-bottom: 2.5rem;
    }

    &:hover {
      box-shadow: 0 0.25rem 0.625rem 0 hsla(0, 0%, 0%, 0.2);
    }
  }

  &__container {
    @mixin size 100%;
    display: flex;
    align-items: center;
    margin: auto 0;
  }

  &__profileAvatarWrapper {
    @mixin size 3.8125rem;
    margin: 1.1875rem 1.9375rem 1.125rem 1.8125rem;
  }

  &__profileAvatar {
    @mixin size 1rem;
  }

  &__label {
    display: block;
    flex: 1;
    overflow: hidden;
    color: var(--color_label-fg);
  }

  &__name {
    display: block;
    font-size: 1.5rem;
    text-align: left;
  }

  &__arrow {
    display: block;
    width: 0.625rem;
    height: 1.0625rem;
    margin: 0 1.25rem;
    fill: var(--color_profilesList-arrow);
  }

  &__disclaimer {
    color: var(--color_grey-300);
    text-align: center;

    &__line {
      display: block;
    }
  }

  &__addProfileButton {
    width: 100%;
    height: 3.125rem;
  }
}
