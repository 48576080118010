.SkipLink {
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s ease;

  &:focus {
    pointer-events: auto;
    opacity: 1;
  }
}
