.zoneSelector {
  display: flex;
  align-items: center;
  margin-top: 1.875rem;

  &__flag {
    width: 1.5rem;
    height: 1.5rem;
    margin-left: 0.625rem;
  }

  &__country {
    font-family: var(--font_hind);
    font-size: 0.9375rem;
    font-weight: 600;
    color: var(--color_zoneSelector-country);

    &:hover {
      color: var(--color_zoneSelector-country-hover);
    }

    @mixin isDark {
      color: var(--color_darkmode-zoneSelector-country);
      &:hover {
        color: var(--color_darkmode-zoneSelector-country-hover);
      }
    }
  }
}
