.profileSelector {
  margin: 0 0.5rem;

  @media (--mq-from-tablet) {
    margin: 0;
  }

  @mixin tvDevice {
    max-width: 100vw;
    margin: 0;
    overflow: hidden;
  }

  &--fadeIn {
    animation: 0.3s ease-in 0.2s both fadein;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    max-width: 13.75rem;

    @media screen and (min-width: 346px) {
      max-width: 20.625rem;
    }

    @media screen and (min-width: 480px) {
      max-width: 27.5rem;
    }

    @media screen and (min-width: 600px) {
      max-width: 34.375rem;
    }

    @media screen and (min-width: 700px) {
      max-width: 40.625rem;
    }

    @media screen and (min-width: 960px) {
      max-width: 57rem;
    }

    @media screen and (min-width: 1024px) {
      max-width: 61.6875rem;
    }

    @mixin tvDevice {
      position: relative;
      display: block;
      max-width: 100%;
      padding: 0.6rem 28rem 0;
      overflow-x: visible;
      overflow-y: hidden;
      white-space: nowrap;
    }
  }

  &__item {
    position: relative;
    display: block;
    width: 5.875rem;
    margin: 0.5rem;

    @media screen and (min-width: 700px) {
      width: 7.125rem;
    }

    @media screen and (min-width: 960px) {
      width: 8.5rem;
    }

    @media screen and (min-width: 1024px) {
      width: 9.1875rem;
    }

    @mixin tvDevice {
      display: inline-block;
      width: 13rem;
      margin-right: 3.25rem;
      margin-left: 0;
      vertical-align: top;
    }
  }

  &__itemAvatar {
    width: 100%;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
