@define-mixin background {
  position: fixed;
  top: 0;
  z-index: var(--z-index-10);
  display: none;
}

.immersive {
  @mixin background;
  @mixin size 100%, 100vh;
  display: block;
  padding: 0;

  /* reset dialog's style */
  color: var(--color_text-primary);
  background-color: rgba(28, 28, 28, 0.9);
  border: 0;

  &__backgroundTv {
    @mixin background;
    @mixin size 100%, 100vh;
    display: block;
    background-color: rgba(28, 28, 28, 1);
  }

  &--isDefault {
    background: var(--color_black-85);

    @mixin tvDevice {
      background: var(--color_background-v5);
    }
  }

  &__wrapper {
    position: absolute;
    top: 0;
    width: 100vw;
    height: 100vh;
    margin: 0 auto;
    overflow-x: hidden;
    overflow-y: scroll;
    cursor: pointer;
    opacity: 1;
    -webkit-overflow-scrolling: auto;

    &--noScroll {
      z-index: var(--z-index-1);
      overflow: hidden;
    }

    &--isSlideshow {
      display: flex;
      align-items: center;
    }

    @mixin tvDevice {
      @mixin hideScrollbar;
    }

    @mixin deviceFamily playstation {
      overflow: hidden;
    }
  }

  &__container {
    width: 100%;
    max-width: 80rem;
    /* This margin-bottom must be apply in a container without scroll and overflow properties defined.
    Otherwise it will be ignored by FF and IE.
    */
    margin: 0 auto;
    cursor: default;
    outline: none;

    @media (--mq-from-laptop) {
      margin-bottom: 3.75rem;
    }

    @media (--mq-from-tablet) {
      width: 90%;
    }

    @media (--mq-below-height-threshold) {
      max-width: 62.5rem;
    }

    &__backdrop {
      @mixin absolutePosition fixed;
      z-index: 10;
      cursor: pointer;
    }

    &__overlay {
      z-index: var(--z-index-nav);
      background-color: var(--color_background-v5);
      opacity: 1;
      @mixin absolutePosition;

      &--fadeOut {
        pointer-events: none;
        animation: 1s fadeOut forwards;
      }
    }

    &__content {
      position: relative;
      z-index: 20;
    }

    &--immersiveFull {
      width: 100%;
      max-width: 100%;
      margin: 0;
    }

    &--isDetailV5 {
      margin: 0 auto;
      background: var(--color_detail-content);

      @media (--mq-from-tablet) {
        width: var(--size_detailv5_immersive_width);
        margin: 1.5rem auto 1.25rem;
        overflow: clip;
        border-radius: 1.25rem;
      }

      @mixin tvDevice {
        width: 100vw;
        max-width: 100vw;
        margin: 0;
        border-radius: unset;
        clip-path: unset;
      }
    }

    &--isSlideshow {
      width: fit-content;
      height: fit-content;
      margin: 1.5rem auto 1.25rem;
      background: var(--color_detail-content);
      clip-path: inset(0 0 0 0 round 1.25rem);

      @mixin tvDevice {
        width: 100vw;
        max-width: 100vw;
        margin: 0;
        border-radius: none;
        clip-path: unset;
      }
    }

    &--isDetailLight {
      width: 100%;
      max-width: 100%;
      height: 100%;
      margin: 0;
      background-color: var(--color_background-v5);
    }
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

:global {
  .immersive-noscroll {
    overflow: hidden !important;
  }
}
