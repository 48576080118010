.setting {
  --color_setting-light-bg: var(--background-page);
  --color_setting-dark-bg: var(--color_primary-card-shadow);
  --color_setting-dark-border: var(--color_darkmode-tertiary);
  --font-size_setting: 0.875rem;

  [data-theme~='telecomitalia'] & {
    --color_setting-light-bg: var(--color_gold-dark-secondary);
    --color_setting-dark-bg: var(--color_gold-dark-secondary);
    --color_setting-dark-border: var(--color_gold-dark-tertiary);
  }

  @media (--mq-from-tablet) {
    --font-size_setting: 1rem;
  }

  display: flex;

  align-items: flex-start;
  justify-content: space-between;
  padding: 1.25rem 0;

  @mixin isDark {
    &:not(:last-child) {
      border-bottom-color: var(--color_darkmode-line);
    }

    .setting {
      &__button {
        color: var(--color_white);
      }
    }
  }

  &__multilinks {
    padding: 0 0 1.25rem;
  }

  &__withBorder {
    display: flex;
    align-items: center;
    width: 100%;
    max-height: 3rem;
    padding: 0.9375rem 0.9375rem 0.8125rem;
    background-color: var(--color_setting-light-bg);
    border: 0.0625rem solid var(--color_settings-border);
    border-radius: var(--border-radius-big);

    [data-theme~='telecomitalia'] & {
      border: none;
    }

    @mixin isDark {
      background-color: var(--color_setting-dark-bg);
      border: none;

      &.setting__sticky:last-child {
        border-top: 0.0625rem solid var(--color_settings-border);
      }
    }

    .setting {
      &__type {
        margin-right: 1.25rem;
      }

      &__type,
      &__action {
        display: flex;
        align-items: center;
        font-size: 0.875rem;

        &--medium {
          font-size: var(--font-size_setting);
        }
      }

      &__action {
        margin-left: auto;
      }

      @mixin isDark {
        background-color: var(--color_setting-dark-bg);
      }
    }
  }

  &__sticky:not(:last-child) {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  &__sticky:last-child {
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  &__type,
  &__title {
    margin: 0;
    font-weight: normal;
  }

  &__title {
    margin: 0 0 0.625rem;
    font-size: 1.25rem;
  }

  &__text {
    margin: 0;
    font-size: 0.9375rem;
  }

  &__list {
    padding-left: 1.125rem;
    font-size: 0.9375rem;

    li {
      list-style: disc;
    }
  }

  &__action {
    a,
    button {
      color: var(--color_link-normal);
      transition: color 300ms ease-in;

      @mixin tvDevice {
        transition: none;
      }

      &:hover {
        color: var(--color_link-hover);
      }
    }

    &__multilinks {
      padding: 0 0 0.625rem;
      text-align: right;
      &__last {
        padding: 0;
      }
    }

    a {
      [data-theme~='telecomitalia'] & {
        text-decoration: underline;
      }
    }
  }

  &__button {
    display: flex;
    align-items: center;
    width: 100%;

    ._action {
      margin-left: auto;
    }
  }

  &__withBorder&__sticky:last-child {
    [data-theme~='telecomitalia'] & {
      border-top: 0.0625rem solid var(--color_settings-border);
    }
  }

  &_tv {
    &_title {
      margin: 0 0 0.625rem;
      font-family: var(--font_hind);
      font-size: 1.25rem;
      font-weight: 600;
    }
    &_info {
      margin-top: 1rem;
      font-size: 1.9375rem;
      line-height: 2.25rem;
      color: var(--color_white-50);
      letter-spacing: -0.0125rem;
    }
    &_button {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 1rem 1.25rem 0.5625rem;
      margin-top: 1rem;
      font-size: 2.5rem;
      line-height: 2.625rem;
      color: var(--color_white-70);
      border-radius: var(--border-radius-extraLarge);
      transition: background-color 200ms ease-out;

      @mixin tvDevice {
        transition: none;
      }

      &--title {
        max-width: 31.25rem;
        text-align: left;
      }
      &--choice {
        display: flex;
        align-items: center;
        font-weight: 500;
        color: var(--color_white-30);
      }
      &--icon {
        width: 3.125rem;
        margin: -0.9375rem 0 -0.375rem;
        fill: var(--color_white-70);
      }
    }
  }
}
