.flipSwitch {
  &__labelText {
    display: inline-block;
    margin-right: 0.9375rem;
    font-size: 1.25rem;
    vertical-align: middle;
    color: var(--color_form_flipSwitch_label-fg);
    cursor: pointer;
  }

  &__checkbox {
    position: absolute;
    pointer-events: none;
    opacity: 0;
  }

  &__toggle {
    @mixin size 3.5625rem, 1.875rem;
    position: relative;
    display: inline-block;
    padding: 0.125rem;
    vertical-align: middle;
    cursor: pointer;
    user-select: none;

    border-radius: 1.875rem;
    transition: background 400ms;

    &::before,
    &::after {
      position: absolute;
      display: block;
      content: '';
    }

    &::before {
      top: 0.125rem;
      right: 0.125rem;
      bottom: 0.125rem;
      left: 0.125rem;
      background-color: var(--color_form-flipSwitch-off);
      border-radius: 1.875rem;
      transition: background 400ms;
    }

    &::after {
      top: 0.25rem;
      bottom: 0.25rem;
      left: 0.25rem;
      width: 1.4375rem;
      background-color: var(--color_white);
      border-radius: 1.4375rem;
      transition:
        margin 400ms,
        background 400ms;
    }

    @mixin isDark {
      &::before {
        background-color: var(--color_darkmode-settings-background);
      }
    }
  }

  &__checkbox[value='true'] + .flipSwitch__toggle {
    &::before {
      background-color: var(--color_form_flipSwitch);
    }
  }

  &__checkbox[value='true'] + .flipSwitch__toggle::after {
    margin-left: 1.625rem;
  }

  &__label__focus [type='checkbox']:focus + .flipSwitch__toggle__focus {
    outline: var(--color_form_focus_outline) solid 1px;
    box-shadow: 0 0 8px var(--color_form_focus_outline);
  }
}
