.player {
  &__playerContainer {
    @mixin size 100vw, 100vh;
    position: fixed;
    top: 0;
    z-index: 1002;
    display: block;
    opacity: 1;

    &--hidden {
      @mixin tvDevice {
        display: none;
        opacity: 0;
        transition: opacity 500ms ease-in;
      }
    }
  }

  &__playerWrapper {
    @mixin size 100%;
    position: relative;
  }

  &__player {
    @mixin size 100%;
    position: relative;
    z-index: 1000;
    display: block;
    margin: 0 auto;
    background-color: var(--color_black);
  }
}
