@define-mixin tv-focus-pseudo-border {
  position: absolute;
  top: -0.3125rem;
  left: -0.3125rem;
  width: calc(100% + 0.625rem);
  height: calc(100% + 0.625rem);
  content: '';
  background-color: var(--color_focus-outline);
  border-radius: var(--border-radius-big-outline);
}

@define-mixin tv-focus-border {
  border: 0.25rem solid var(--color_focus-outline);
  border-radius: var(--border-radius-big-outline);
}

@define-mixin tv-focus-outline {
  outline: 0.25rem solid var(--color_focus-outline);
  border-radius: var(--border-radius-big-outline);
}

@define-mixin tv-focus-shadow {
  box-shadow: 0 0 0 0.25rem var(--color_focus-border);
}

@define-mixin tv-focus-shadow-rounded {
  @mixin tv-focus-shadow;
  border-radius: 50%;
}

@define-mixin tv-focus-button-base {
  color: var(--color_focus-fg);
  background-color: var(--color_focus-bg);
}

@define-mixin tv-focus-button {
  @mixin tv-focus-button-base;
  transform: scale(1.04);
}

@define-mixin tv-focus-button-with-shadow {
  @mixin tv-focus-button;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.5);
}

@define-mixin tv-focus-not-clickable-item-with-shadow {
  --color_notClickableItem-tv-bg-focus: var(--color_mine-shaft);

  [data-theme~='telecomitalia'] & {
    --color_notClickableItem-tv-bg-focus: var(--color_gold-dark-secondary);
  }

  background-color: var(--color_notClickableItem-tv-bg-focus);
  border-radius: 0.5rem;
  box-shadow: 0 8px 10px 0 var(--color_black-50);
}
