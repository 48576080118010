.avatarV2Selection {
  --HZL-list-side-padding: 2rem;

  padding-bottom: 1.25rem;

  &__title {
    padding-top: 0.875rem;
    padding-bottom: 0.875rem;
    margin-top: 3.19rem;
    margin-bottom: 0;
    -webkit-line-clamp: 1;
    font-family: var(--font_canal);
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1.75rem;
    text-align: center;
    text-transform: uppercase;

    @mixin tvDevice {
      padding-bottom: 1rem;
      padding-left: 4.38rem;
      margin-bottom: 1.5rem;
      font-size: 3rem;
      line-height: 3.25rem;
      text-align: left;
    }
  }

  &__strate {
    &:not(:last-child) {
      margin-bottom: 1rem;
    }

    @mixin tvDevice {
      margin-bottom: 2.5rem;
    }

    &__list {
      @mixin tvDevice {
        padding-top: 0.5rem;
        padding-right: 4.38rem;
        padding-bottom: 0.5rem;
        padding-left: 4.38rem;
      }
    }

    &__title {
      display: flex;
      padding-top: 1.375rem;
      padding-bottom: 0.5rem;
      padding-left: 2rem;
      overflow: visible;
      font-family: var(--font_hind);
      font-size: 1.375rem;
      font-weight: 600;
      line-height: 1.625rem;

      @media (--mq-from-tablet) {
        font-size: 1.625rem;
        line-height: 1.875rem;
      }

      @mixin tvDevice {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        padding-left: 4.38rem;
        font-size: 2.625rem;
        line-height: 2.875rem;
      }
    }

    &__avatar {
      display: inline-block;
      width: 6.8125rem;

      @media (--mq-from-tablet) {
        width: 7.4rem;
      }

      @media (--mq-from-laptop) {
        width: 8rem;
      }

      &:not(:last-child) {
        margin-right: 0.5rem;

        @media (--mq-from-tablet) {
          margin-right: 1rem;
        }
      }

      @mixin tvDevice {
        width: 12.96431rem;
        height: 12.96431rem;

        &:not(:last-child) {
          margin-right: 3rem;
        }
      }
    }
  }
}
