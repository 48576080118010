.buttonLinker {
  display: flex;
  align-items: center;
  overflow: hidden;

  svg {
    vertical-align: middle;
  }

  &--menu-option {
    justify-content: space-between;
    width: 100%;
  }
}
